import React from "react"
import Image from "../../components/image/contact"
import ContactBox from "../../components/contactBox"

export default () => (
  <div className="container mx-auto my-12 lg:my-24" id="contact">
    <div className="grid grid-cols-1 lg:grid-cols-2">
      <div style={{ maxWidth: `375px` }} className="mx-24 lg:ml-64 mt-24">
          <Image />
      </div>
      <div className="mt-12 mx-8">
        <p className="text-2xl lg:text-4xl font-bold text-blue-600 mb-4 lg:mb-8">
          Hubungi Kami
        </p>
        <div className="flex flex-col">
          <p className="font-bold text-lg lg:text-2xl">Perguruan Tinggi</p>
          <div className="grid grid-cols-2">
            <ContactBox name="Setya Intan" line="setyaintan3" wa="085797915269" />
            <ContactBox name="Dhinar" line="dhinar___" wa="08231413033" />
          </div>
          <p className="font-bold text-lg lg:text-2xl mt-2 lg:mt-4">SMK/SMA</p>
          <div className="grid grid-cols-2">
            <ContactBox name="Peggy" line="callista.21" wa="085268711780" />
            <ContactBox name="Annisa" line="annisasdnff" wa="082260052968" />
          </div>
        </div>
      </div>
    </div>
  </div>
)
