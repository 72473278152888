import React from "react"
import Image from "../../components/image/timeline"

export default () => (
  <div className="container mx-auto my-12 lg:my-24">
    <div className="grid grid-cols-1 lg:grid-cols-2">
      <div style={{ maxWidth: `700px` }} className="mx-8">
        <Image />
      </div>
      <div className="mt-12 mx-8">
        <p className="text-2xl lg:text-4xl font-bold text-green-500">
          Timeline
        </p>
        <p className="mt-4 lg:mt-8 lg:text-xl text-justify lg:text-left">
          Penutupan Pendaftaran Abstrak:{" "}
          <span className="font-bold">25 April</span>
          <br />
          Pengumuman Lolos Seleksi Abstrak:{" "}
          <span className="font-bold">28 April</span>
          <br />
          Pengumpulan Full paper:{" "}
          <span className="font-bold">28 April - 7 Mei</span>
          <br />
          Pengumuman Lolos Seleksi Full Paper:{" "}
          <span className="font-bold">11 Mei</span>
          <br />
          Green Energy Camp:{" "}
          <span className="font-bold">24 - 28 Mei</span>
          <br />
          Batas Pengumpulan Video Prototype:{" "}
          <span className="font-bold">29 Mei</span>
          <br />
          Presentasi Grand Final:{" "}
          <span className="font-bold">31 Mei</span>
          <br />
          Awarding:{" "}
          <span className="font-bold">9 Juni</span>
          <br />
        </p>
      </div>
    </div>
  </div>
)
