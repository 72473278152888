import React from "react"
import { Link } from "gatsby"
import Image from "../../components/image/background"
import Button from "../../components/Button"

export default () => (
  <div className="container mx-auto my-12">
    <div className="grid grid-cols-1 lg:grid-cols-2">
      <div className="mt-12 mx-8 lg:mx-16">
        <p className="text-3xl lg:text-5xl font-bold text-green-500">
          ASTRA GREEN ENERGY
        </p>
        <p className="text-lg lg:text-2xl text-gray-400 font-semibold">
          STUDENT INNOVATION 2021
        </p>
        <p className="mt-4 lg:mt-8 text-sm md:text-base text-justify lg:text-left">
          Astra Green Energy Student Innovation merupakan kompetisi yang diikuti
          oleh mahasiswa Perguruan Tinggi seluruh Indonesia dengan tujuan untuk
          mengkomunikasikan Astra Green Energy kepada stakeholder terkait serta
          untuk mendapatkan dukungan, sinergi dan menggali ide-ide inovasi untuk
          keberlanjutan ketahanan energi terutama dari pihak akademisi.
        </p>
        <div className="mt-8">
          <Link to="/finalist">
            <Button className="mr-4 mb-4 bg-green-500 text-white focus:outline-none hover:bg-green-700">
              Lihat Finalis
            </Button>
          </Link>
        </div>
      </div>
      <div style={{ maxWidth: `700px` }} className="order-first lg:order-last">
        <Image />
      </div>
    </div>
  </div>
)
