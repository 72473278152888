import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/Button"

import { Formik, Field } from "formik"
import firebase from "gatsby-plugin-firebase"
import axios from "axios"

import { Head, Background, Theme, TotalPrize, Timeline, FAQ, Contact } from "../sections/home"

const IndexPage = () => {
  const [submit, setSubmit] = React.useState({ done: false, message: "" })

  return (
    <Layout>
      <SEO title="Home" />
      <Head />
      <Background />
      <Theme />
      <TotalPrize />
      <Timeline />
      <FAQ />
      <Contact />
      <div className="grid grid-cols-1 lg:grid-cols-2 min-h-screen" id="daftar">
        <div className="bg-blue-800 text-white flex items-center justify-center py-8">
          <Formik
            initialValues={{
              name: "",
              email: "",
              job: "",
              instancy: "",
              major: "",
              checkOption: [],
            }}
            validate={values => {
              const errors = {}
              if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address"
              }
              return errors
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmit({ done: true, message: "Loading" })
              firebase
                .database()
                .ref(`/registrant`)
                .push(values)
                .then(value => {
                  setSubmitting(false)
                  setSubmit({ done: true, message: "Success!" })
                  axios.post(
                    "https://us-central1-sre-website-a43e8.cloudfunctions.net/sendEmailOnAstraRegistration",
                    { email: values.email }
                  )
                })
                .catch(error => {
                  setSubmitting(false)
                  setSubmit({
                    done: true,
                    message: "Failed, Please refresh this page and try again",
                  })
                })
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form
                onSubmit={handleSubmit}
                className="flex flex-col mx-8 lg:mx-auto"
                style={{ minWidth: `70%` }}
              >
                <p className="text-2xl lg:text-4xl mb-4 lg:mb-12 font-bold">Daftarkan dirimu sekarang!</p>
                <label htmlFor="name" className="text-lg lg:text-xl">
                  Nama Lengkap:
                </label>
                <input
                  type="text"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  className="ring-white ring-1 rounded-lg bg-blue-800 my-2 px-4 py-1 outline-none focus:outline-none text-white placeholder-gray-300 mb-4"
                  placeholder="Tuliskan nama lengkap kamu di sini"
                  required={true}
                />
                {errors.name && touched.name && errors.name}
                <label htmlFor="name" className="text-lg lg:text-xl">
                  Email:
                </label>
                <input
                  type="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  className="ring-white ring-1 rounded-lg bg-blue-800 my-2 px-4 py-1 outline-none focus:outline-none text-white placeholder-gray-300 mb-4"
                  placeholder="johndoe@example.com"
                  required={true}
                />
                {errors.email && touched.email && errors.email}
                <label htmlFor="job" className="text-lg lg:text-xl">
                  Pekerjaan:
                </label>
                <input
                  type="text"
                  name="job"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.job}
                  className="ring-white ring-1 rounded-lg bg-blue-800 my-2 px-4 py-1 outline-none focus:outline-none text-white placeholder-gray-300 mb-4"
                  placeholder="Pelajar/Mahasiswa/Lainnya"
                  required={true}
                />
                {errors.job && touched.job && errors.job}
                <label htmlFor="instancy" className="text-lg lg:text-xl">
                  Instansi/Universitas/Sekolah:
                </label>
                <input
                  type="text"
                  name="instancy"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.instancy}
                  className="ring-white ring-1 rounded-lg bg-blue-800 my-2 px-4 py-1 outline-none focus:outline-none text-white placeholder-gray-300 mb-4"
                  placeholder="Tuliskan tempat kamu bekerja/belajar di sini"
                  required={true}
                />
                {errors.instancy && touched.instancy && errors.instancy}
                <label htmlFor="major" className="text-lg lg:text-xl">
                  Jurusan:
                </label>
                <input
                  type="text"
                  name="major"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.major}
                  className="ring-white ring-1 rounded-lg bg-blue-800 my-2 px-4 py-1 outline-none focus:outline-none text-white placeholder-gray-300"
                  placeholder="Tuliskan jurusan kamu di sini (e.g. Mechanical Engineering)"
                  required={true}
                />
                {errors.instancy && touched.instancy && errors.instancy}
                <div
                  role="group"
                  aria-labelledby="checkbox-group"
                  className="mt-4 flex flex-col"
                >
                  <label>
                    <Field
                      type="checkbox"
                      name="checkOption"
                      value="Day 1: Lighting Up Indonesia"
                      className="mr-4"
                    />
                    Day 1: Lighting Up Indonesia
                  </label>
                  <label>
                    <Field
                      type="checkbox"
                      name="checkOption"
                      value="Day 2: Innovation of Renewable Energy"
                      className="mr-4"
                    />
                    Day 2: Innovation of Renewable Energy
                  </label>
                  <label>
                    <Field
                      type="checkbox"
                      name="checkOption"
                      value="Day 3: Astra Green Energy Student Innovation 101"
                      className="mr-4"
                    />
                    Day 3: Astra Green Energy Student Innovation 101
                  </label>
                  <label>
                    <Field
                      type="checkbox"
                      name="checkOption"
                      value="Share Poster"
                      className="mr-4"
                    />
                    Share Poster
                  </label>
                  <p className="mt-4">
                    <a
                      href="https://firebasestorage.googleapis.com/v0/b/agensi/o/POSTER%20SRE1.png?alt=media&token=8fef0943-82db-4774-80df-82962873d65e"
                      target="_blank"
                      className="text-white underline"
                    >
                      Download poster
                    </a>
                  </p>
                </div>
                <div className="my-6">
                  <Button type="submit" disabled={isSubmitting || submit.done} className="text-white bg-gray-500 hover:bg-gray-700">
                    Daftar Sekarang
                  </Button>
                </div>
                <p className="text-lg">{submit.message || " "}</p>
              </form>
            )}
          </Formik>
        </div>
        <div className="flex flex-col items-center justify-center py-8">
          <p className="text-xl lg:text-5xl text-green-600 font-bold text-center">
            The future is green energy, sustainability, renewable energy.
          </p>
          <p className="text-lg lg:text-2xl text-center mt-8">
            - Arnold Schwarzenegger
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
