import React from "react"
import WhatIsAgensiImg from "../../components/image/whatIsAgensi"
import BluePolygon from "../../components/image/bluePolygon"
import Ellipse from "../../components/image/ellipse"
import Star from "../../components/image/star"
import Button from "../../components/Button"
import { Link } from "gatsby"

export default () => (
  <div className="container mx-auto mt-12">
    <div className="hidden lg:flex items-end justify-end">
      <div className="w-24 my-12 mx-24">
        <BluePolygon />
      </div>
    </div>
    <div className="grid grid-cols-1 lg:grid-cols-2">
      <div className="mx-16 lg:ml-32" style={{ maxWidth: `500px` }}>
        <WhatIsAgensiImg />
      </div>
      <div className="mt-12 mx-8 lg:mr-24">
        <p className="text-2xl lg:text-4xl">
          Apa itu <span className="font-bold">AGEn SI</span>?
        </p>
        <p className="mt-4 lg:mt-8 text-sm md:text-base text-justify lg:text-left">
          Astra Green Energy Student Innovation merupakan kompetisi yang diikuti
          oleh mahasiswa Perguruan Tinggi dan siswa SMA/SMK/MA dan sederajat
          seluruh Indonesia dengan tujuan untuk mengkomunikasikan Astra Green
          Energy kepada stakeholder terkait serta untuk mendapatkan dukungan,
          sinergi dan menggali ide-ide inovasi untuk keberlanjutan ketahanan
          energi terutama dari pihak akademisi.
        </p>
        <div className="mt-8">
          <Link to="/about">
            <Button className="mr-4 mb-4 text-gray-600 border border-gray-600 hover:bg-gray-600 hover:text-white">Tentang Kami</Button>
          </Link>
        </div>
      </div>
    </div>
    <div className="hidden lg:flex items-end justify-end">
      <div className="w-16 mx-48">
        <Ellipse />
      </div>
    </div>
    <div className="hidden lg:flex">
      <div className="w-48 mx-24">
        <Star />
      </div>
    </div>
  </div>
)
