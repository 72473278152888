import React from "react"
import Image from "../../components/image/theme"
import Button from "../../components/Button"

export default () => (
  <div className="container mx-auto my-12 lg:my-auto">
    <div className="grid grid-cols-1 lg:grid-cols-2">
      <div className="lg:flex justify-end">
        <div className="mt-12 mx-8 lg:mx-16 lg:w-1/2">
          <p className="text-2xl lg:text-4xl">
            <span className="font-bold">Tema</span> Tahun Ini
          </p>
          <p className="mt-4 lg:mt-8 text-sm md:text-base text-justify lg:text-left">
            “Inovasi Efisiensi Energi pada Sektor Energi Baru dan Terbarukan (EBT)
            untuk Rural dan Urban Electrification”
          </p>
          <div className="mt-8">
            <a
              href="https://drive.google.com/drive/folders/1mU9g0bYmhdG9tcDdkjhiqrt82fLtak9w"
              target="_blank"
              rel="norefferer"
            >
              <Button className="mr-4 mb-4 text-gray-600 border border-gray-600 hover:bg-gray-600 hover:text-white">
                Unduh Kit
              </Button>
            </a>
          </div>
        </div>
      </div>
      <div style={{ maxWidth: `700px` }} className="order-first lg:order-last mx-8">
        <Image />
      </div>
    </div>
  </div>
)
