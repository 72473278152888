import React from "react"

export default () => (
  <div className="bg-red-400">
    <div className="container mx-auto p-20 my-12">
      <p className="text-white text-center text-2xl lg:text-4xl">
        Total Hadiah <span className="font-bold">IDR 60 Juta</span>
      </p>
    </div>
  </div>
)
