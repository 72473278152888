import React from "react"
import { FaWhatsapp, FaLine } from "react-icons/fa"

export default ({ name, line, wa }) => (
  <div className="flex flex-col my-4 gap-y-1">
    <p className="lg:text-lg font-semibold">{name}</p>
    <div className="flex gap-2">
      <FaLine size={20} className="text-green-500" />
      <p className="text-sm lg:text-base">{line}</p>
    </div>
    <div className="flex gap-2">
      <FaWhatsapp size={20} className="text-green-700" />
      <p className="text-sm lg:text-base">{wa}</p>
    </div>
  </div>
)
