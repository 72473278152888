import React from "react"
import Image from "../../components/image/faq"

export default () => (
  <div className="container mx-auto lg:my-24" id="faq">
    <div className="grid grid-cols-1 lg:grid-cols-2">
      <div className="mt-12 mx-8">
        <p className="text-2xl lg:text-4xl font-bold text-red-400">
          Frequently Asked Questions
        </p>
        <p className="mt-4 lg:mt-8 lg:ml-4 text-sm md:text-base text-justify lg:text-left">
          Q: Apakah ini lomba untuk individu atau team?<br/>
          A : Team (2-3orang)<br/>
          <br/>
          Q: Bagaimana cara mengikuti kompetisi Astra Green Student Innovation 2021?<br/>
          A : Peserta harus mengikuti webinar Road to agensi terlebih dahulu minimal 1 kali, dengan perwakilan 2 orang dan masing-masing team. Kemudian peserta dapat mengumpulkan abstrak melalui website astra2021.srenasional.com yang akan diupdate dengan melampirkan bukti kehadiran, twibbon, karya, dan file-file yang dibutuhkan.<br/>
          <br/>
          Q: Siapa saja yang bisa mengikuti perlombaan ini?<br/>
          A : Mahasiswa perguruan tinggi (D1/D3/D4/S1) dan siswa SMA/SMK/MA/Sederajat.<br/>
          <br/>
          Q: Bagaimana bentuk output dari perlombaan ini?<br/>
          A: Berupa video prototype beserta full paper yang akan dipresentasikan di tahap terakhir<br/>
          <br/>
          Q: Apakah wajib pakai guru pembimbing?<br/>
          A: Ya, setiap tim wajib didampingi satu guru atau dosen pembimbing<br/>
          <br/>
          Q: Apakah satu orang bisa mengikuti lomba dalam 2 tim sekaligus?<br/>
          A : Bisa, tetapi hanya bisa menjadi ketua di salah satu tim.<br/>
          <br/>
          Q : Pendaftarannya perwakilan pertim saja atau semuanya kak?<br/>
          A : Untuk pendaftaran kompetisi diwakilkan oleh satu orang saja, biodata anggota tim dimasukkan di lembar pengesahan.<br/>
          <br/>
        </p>
      </div>
      <div style={{ maxWidth: `500px` }} className="order-first lg:order-last mx-16 mt-24">
        <Image />
      </div>
    </div>
  </div>
)
